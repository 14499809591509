import { IAddressLean } from "../../../shared/domain/address/address-lean";
import { IPaginationMeta } from "../../../shared/domain/pagination/pagination-meta";
import { ICfmItemX } from "./cfm-item-x";
import { CfmOrderXApiStatus } from "./cfm-order-x.status";

export interface ICfmOrderX {
  id: number;
  status: CfmOrderXApiStatus;
  truckloadId: number | null;
  routingPostal: string | null;
  purchaseOrderNumber: string | null;
  customerAddress: IAddressLean | null;
  logisticAddress: IAddressLean | null;
  destinationAddress: IAddressLean | null;
  contactPersonName: string | null;
  contactPersonTelephone: string | null;
  contactPersonEmail: string | null;
  openingHours: string | null;
  orderComment: string | null;
  servicePickupDate: Date | null;
  serviceDeliveryDate: Date | null;
  serviceTreatmentDate: Date | null;
  serviceRecyclingDate: Date | null;
  requestedPickupDate: Date | null;
  plannedPickupDate: Date | null;
  actualPickupDate: Date | null;
  requestedDeliveryDate: Date | null;
  plannedDeliveryDate: Date | null;
  actualDeliveryDate: Date | null;
  shoppingCartDate: Date | null;
  orderDate: Date | null;
  logisticAcceptedDate: Date | null;
  logisticAnnouncedDate: Date | null;
  logisticPerformedDate: Date | null;
  logisticWarehouseDate: Date | null;
  logisticTruckLoadAnnouncedDate: Date | null;
  logisticTruckLoadPerformedDate: Date | null;
  recyclerIncomingWarehouseDate: Date | null;
  recyclerOutgoingWarehouseDate: Date | null;
  destinationArrivedDate: Date | null;
  destinationReceivedDate: Date | null;
  confirmedDate: Date | null;
  canceledDate: Date | null;
  items: ICfmItemX[];
  isNew: boolean;
  warehouseWeight: number | null;
}

export interface ICfmOrderXList {
  items: ICfmOrderX[];
  meta: IPaginationMeta;
}

// Is the order's product an aval product?
export function isAvalProduct(order: ICfmOrderX | undefined): boolean {
  const productItem = (order?.items ?? []).find((item) => item.product);
  const result = productItem?.product?.isAval ?? false;
  console.log(`isAval flag: ${productItem?.product?.isAval} result: ${result}`);
  return result;
}
