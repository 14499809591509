import React, { useMemo } from "react";
import { useGetOrderXActionsQuery } from "../../../../repositories/queries/order-x/queries/get-order-x-actions.query";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { OrderXAction } from "./order-x-action.component";
import { SelectedFilesProvider } from "./providers/selected-files.provider";
import { Loading } from "../../../../../shared/components/loading/loading.component";
import { Grid, makeStyles } from "@material-ui/core";
import { CfmOrderActionType } from "../../../../domain/order-x/actions/cfm-order-action-type";
import { useOrderXOverviewContext } from "../../order-x-overview.provider";
import { isTruckloadStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { ORDER_STATUS_ALL } from "../../utils/order-x-card.util";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { isAvalUser } from "../../../../../shared/domain/user/user";
import { isAvalProduct } from "../../../../domain/order-x/cfm-order-x";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    minWidth: 30,
    marginRight: 12,
  },
}));

export const OrderXActions: React.VFC = () => {
  const { order } = useOrderXSelectedContext();
  const { curFilter } = useOrderXOverviewContext();
  const { internalUser } = useAuthContext();
  const classes = useStyles();
  const productItem = order.items.find((item) => item.product);
  const product = productItem?.product;

  const areActionsDisabled = useMemo(() => {
    return (order.truckloadId && isTruckloadStatus(order.status) && curFilter.statusFilter === ORDER_STATUS_ALL) || (isAvalUser(internalUser) && isAvalProduct(order));
  }, [order, curFilter.statusFilter]);

  const { data, isLoading } = useGetOrderXActionsQuery(order.id, !areActionsDisabled);

  // certain actions should not be displayed here
  const actionsToIgnore: CfmOrderActionType[] = [
    CfmOrderActionType.EditInShoppingCart, // can only be used inside basket
  ];

  if (!productItem || !product) {
    return <></>;
  }
  return (
    <SelectedFilesProvider>
      {isLoading && (
        <div className={classes.loadingContainer}>
          <Loading size={30} isLoading={isLoading} />
        </div>
      )}
      <Grid container direction="row" spacing={1}>
        {!isLoading &&
          !areActionsDisabled &&
          data &&
          data
            .filter((d) => !actionsToIgnore.includes(d.type))
            .map((action, index) => {
              return (
                <Grid item xs={12} sm="auto" key={`${action.type}-${index}`}>
                  <OrderXAction action={action} key={`${order.id}-${action.type}`} />
                </Grid>
              );
            })}
      </Grid>
    </SelectedFilesProvider>
  );
};
